<template lang="pug">
  .control-label(v-if="label" :class="{'v2': v2}")
    span(v-if="required && !v2").required * 
    span.control-label_text {{ label }} 
    span(v-if="required && v2").required.ml-1 *
</template>

<script>
export default {
  name: 'BaseControlLabel',

  props: {
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    v2: Boolean
  }
}
</script>

<style lang="scss">
.control-label {
  display: flex;
  align-items: center;
  padding-top: 10px;
  height: 28px;

  &_ {
    &text {
      margin-bottom: 2px;
      color: $label-color;
      letter-spacing: normal;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      font-size: 10px;
      font-family: "Roboto", sans-serif;
      line-height: normal;
    }
  }
  &.v2 {
    .control-label_text {
      color: #161616;
      font-weight: 500;
      font-size: 10px;
      font-family: $font;
    }
  }
}

</style>
